<template>
  <base-page>
    <Filters v-if="filterModel" :model="filterModel"
      color="reseau" slot="left-column" />
    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { Filters } from '../../components/filters'

export default {
  name: 'EntityPage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - Réseau | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    store.dispatch('entities/getEntity', currentRoute.params.id)
  },

  beforeDestroy () {
    this.$store.commit('entities/setCurrentEntity', null)
  },

  computed: {
    ...mapGetters({
      entity: 'entities/getCurrentEntity'
    }),

    filterModel () {
      const routeName = this.$route.name
      if (routeName === 'entity-evenements') { return 'evenements' }
      if (routeName === 'entity-tasks') { return 'tasks' }
      return null
    }
  },

  watch: {
    entity (newVal, oldVal) {
      this.$store.commit('pages/setPageTitle', newVal.nom_entite_temporaire ? {
        label: newVal['label'],
        suffixIcon: { name: 'warning', color: 'reseau', size: '1.6rem' }
      } : newVal['label'])

      const id = this.$route.params.id
      const { evenements_count: evenements, taches_count: tasks, cibles_count: cibles } = newVal

      this.$store.commit('pages/setTabs', [
        { name: 'informations', label: 'Informations', to: { name: 'entity-show', params: { id } } },
        { name: 'map', label: 'Carte', to: { name: 'entity-map', params: { id } } },
        { name: 'events', label: `${evenements} ${this.$pluralize(this.$t('evenements.evenement'), evenements)}`, to: { name: 'entity-evenements', params: { id } } },
        { name: 'tasks', label: `${tasks} ${this.$pluralize(this.$t('tasks.task'), tasks)}`, to: { name: 'entity-tasks', params: { id } } },
        { name: 'cibles', label: `${cibles} ${this.$pluralize(this.$t('cibles.cible'), cibles)}`, to: { name: 'entity-cibles', params: { id } } },
        { name: 'notes', label: 'Notes perso', to: { name: 'entity-notes', params: { id } } },
        { name: 'history', label: 'Historique', to: { name: 'entity-show', params: { id } } }
      ])
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('entities.entities_list'), to: { name: 'entities-list' } },
      {}
    ])
  }
}
</script>
